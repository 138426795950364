import EXIF from 'exif-js';
import React, { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useOrganization } from '../OrganizationContext';
import Account from '../api/Account';
import { FailedToUploadImageError, ImageFormatError, ImageSizeError, RemoveImageError, initRemoveImage, initUploadImage } from '../api/Image';
import ButtonSecondary, { SecondaryButtonType, SecondaryButtonVariant } from '../components/ButtonSecondary';
import ContentContainer from '../components/ContentContainer';
import ErrorMessage from '../components/ErrorMessage';
import LoadingSpinner from '../components/LoadingSpinner';
import Modal from '../components/Modal';
import { useDocumentTitle } from '../hooks/UseDocumentTitle';
import { FetchImageStatus, fetchImage } from '../image';
import { useURLHelper } from '../urlhelper';
import './ChangeImagePage.css'


enum ImageInModal {
  Current,
  New,
}
enum ModalContent {
  Image,
  RemoveImage,
}
interface ImageModalProps {
  show: boolean,
  handleClose: React.MouseEventHandler<HTMLButtonElement>,
  content: ModalContent,
  image: ImageInModal,
}
interface Props {
  account: Account;
}
function ChangeImagePage({account}:Props): React.ReactElement {
  const {t} = useTranslation<string>();
  const org = useOrganization();
  const urlHelper = useURLHelper();
  const { image, status } = fetchImage({org, account})
  const sessionStorageKey = `${org.id}-account-image`;
  const [newImage, setNewImage] = useState<Blob>();
  const [newImageName, setNewImageName] = useState<string>();
  const [newImageOrientation, setNewImageOrientation] = useState<number>(1);
  const [currentImage, setCurrentImage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showError, setShowError] = useState<boolean>(false);
  const [isUploadingImage, setIsUploadingImage] = useState<boolean>(false);
  const [isRemovingImage, setIsRemovingImage] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);
  const [contentInModal, setContentInModal] = useState<ModalContent>(ModalContent.Image);
  const [imageInModal, setImageInModal] = useState<ImageInModal>(ImageInModal.Current);
  const orgname = org.name.localize();

  useDocumentTitle({title: 'Change Profile Image'});

  async function uploadNewImage() {
    setIsUploadingImage(true);
    try {
      if (newImage) {
        await initUploadImage({
          orgID: org.backendId,
          accountID: account.id,
          image: newImage,
          orientation: newImageOrientation,
        });
        // Convert new Image to base64 and display as current Image
        const reader = new FileReader();
        reader.readAsDataURL(newImage);
        reader.onloadend = () => {
          if(reader.result) {
            const base64data = reader.result.toString().split(',');
            setCurrentImage(base64data[1]);
            window.sessionStorage.setItem(sessionStorageKey, base64data[1]);
          }        
        }
        setNewImage(undefined)
      }
    } catch (e) {
      if (e instanceof ImageSizeError) {
        setErrorMessage('The file size of the image you selected is too large (Max 5MB). Compress the image or choose a smaller one.');
      } else if (e instanceof ImageFormatError) {
        setErrorMessage('Image file error. Try again or with a different image. Contact helpdesk if the problem persists.');
      } else if (e instanceof FailedToUploadImageError) {
        setErrorMessage('Error uploading image to our systems. Please try again, or contact helpdesk if the problem persists.');
      } else {
        urlHelper.genericError((e as Error).message);
        return;
      }
      setShowError(true);
    }
    setIsUploadingImage(false);
  }

  async function removeUploadedImage() {
    setIsRemovingImage(true);
    setShowModal(false);
    try {
      await initRemoveImage(org.backendId,account.id);
      setCurrentImage('');
      window.sessionStorage.removeItem(sessionStorageKey);
    } catch (e) {
      if (e instanceof RemoveImageError) {
        setErrorMessage('Error removing image. Please try again, or contact helpdesk if the problem persists.');
      } else {
        urlHelper.genericError((e as Error).message);
        return;
      }
      setShowError(true);
    }
    setIsRemovingImage(false);
  }

  function ChooseImageButton(): React.ReactElement {
    const fileRef = useRef<HTMLInputElement>(null);
    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
      if (event.target.files) {
        const file = event.target.files[0];
        // Filetype has to be jpeg or png
        if (file.type != 'image/jpeg' && file.type != 'image/png') {
          setErrorMessage('Filetype is not valid. Image must be a jpeg or png.');
          setShowError(true);
        // Filesize must be under 5MB.
        } else if (file.size > (5 * 1024 * 1024)) {
          setErrorMessage('The file size of the image you selected is too large (Max 5MB). Compress the image or choose a smaller one.');
          setShowError(true);
        } else {
          // If the image is JPEG we fetch the orientation from the exif metadata.
          // Logic for rotating the image is handled in the backend.
          if (file.type === 'image/jpeg') {
            const reader = new FileReader();
            reader.onloadend = () => {
              const arrayBuffer = reader.result as ArrayBuffer;
              const metadata = EXIF.readFromBinaryFile(arrayBuffer);
              if (metadata.Orientation) {
                setNewImageOrientation(metadata.Orientation);
              } else {
                setNewImageOrientation(1);
              }
            }
            reader.readAsArrayBuffer(file);
          }
          setNewImage(event.target.files[0]);
          setNewImageName(event.target.files[0].name);
          setShowError(false);
        }
      }
    }
    return(
      <div className='ChangeImagePage-ChooseImageButton'>
        <ButtonSecondary 
          fullWidth 
          type={SecondaryButtonType.button} 
          onClick={() => fileRef.current && fileRef.current.click()} 
          ariaLabel={t('Upload an image from your device')}>
          {t('Choose image')}
        </ButtonSecondary>
        <input hidden ref={fileRef} onChange={handleChange} type='file' accept='image/jpeg, image/png'/>
      </div>
    )
  }

  const openModal = () => {
    setShowModal(true);
  }
  const hideModal = () => {
    setShowModal(false);
  }

  function ChangeImagePageModal(props: ImageModalProps): React.ReactElement {
    return (
      <Modal
        id='modal'
        isOpen={props.show}
        onRequestClose={props.handleClose}
        title={props.content===ModalContent.RemoveImage ? t('Remove current image') : ''}
      >
        { props.content === ModalContent.Image &&
          <div className='ChangeImagePage-modal-image'>
            { imageInModal===ImageInModal.Current && currentImage && <img src={`data:image;base64, ${currentImage}`} /> }
            { imageInModal===ImageInModal.New && newImage && <img src={URL.createObjectURL(newImage)} alt={t('Profile picture')} /> }
          </div>
        }
        { props.content === ModalContent.RemoveImage &&
          <div>
            <p>{t('Are you sure you want to remove your current image?')}</p>
            <p>{t('This will permanently remove your current photo from our system.')}</p>
            <div className='ChangeImagePage-modal-button-container'>
              <ButtonSecondary onClick={() => removeUploadedImage()} type={SecondaryButtonType.button} disabled={isRemovingImage} variant={SecondaryButtonVariant.outlinedGhost}>{t('Remove')}</ButtonSecondary>
              <ButtonSecondary onClick={() => setShowModal(false)} type={SecondaryButtonType.button} variant={SecondaryButtonVariant.outlinedGhost}>{t('Cancel')}</ButtonSecondary>
            </div>
          </div>

        }
      </Modal>
    )
  }

  useEffect(() => {
    if(image) {
      setCurrentImage(image);
    }
  }, [image])

  return (
    <ContentContainer
      header={t('Change image')}
      title={t('Change image for your account')}
      footer={
        <div className='ChangeImagePage-footer'>
          <ButtonSecondary 
            linkTo={`/${org.id}/profile`} 
            type={SecondaryButtonType.link} 
            variant={SecondaryButtonVariant.contained}>
            {t('Back')}
          </ButtonSecondary>
          {newImage && 
            <ButtonSecondary 
              onClick={() => uploadNewImage()} 
              type={SecondaryButtonType.button} 
              variant={SecondaryButtonVariant.contained} 
              disabled={isUploadingImage} 
              ariaLabel={t('Upload image {{newImageName}}', newImageName)}>
              { isUploadingImage ? <><LoadingSpinner ariaLabel={t('')}/>{t('Uploading')}</> : t('Upload')}
            </ButtonSecondary>
          }
        </div>
      }
    >

      <p> { org.imageRequirementsLink ? 
        <Trans>Make sure you choose an image that <a target='_blank' rel='noopener noreferrer' href={org.imageRequirementsLink.localize()}>meets the requirements</a>.</Trans>
        :
        <Trans>Make sure you choose an image that meets the requirements.</Trans>}
      </p>
      <p> { org.imageUsageLink &&  <Trans>Read about how {{orgname}} <a target='_blank' rel='noopener noreferrer' href={org.imageUsageLink.localize()}>uses your image</a>.</Trans> }</p>
      <p>{t('Remember to press Upload for the image to be updated in our systems.')}</p>
      <div aria-live='polite' role='status'>
        <ErrorMessage  showError={showError} message={errorMessage} />
      </div>
      <div className='ChangeImagePage-image-section'>
        {
          status===FetchImageStatus.Loading ?
            <div className='ChangeImagePage-image-div'>
              <label>{t('Loading image')}</label>
              <div className='ChangeImagePage-image-loading-background'><div className='ChangeImagePage-image-loading'></div></div>
            </div>
            :
            currentImage ?
              <div className='ChangeImagePage-image-div'>
                <label htmlFor='currentImage'>{t('Your image')}</label>
                <img onClick={() => {openModal(); setImageInModal(ImageInModal.Current); setContentInModal(ModalContent.Image)}} alt={t('Current uploaded image')} id='currentImage' className='ChangeImagePage-image' src={`data:image;base64, ${currentImage}`}/>
              </div>
              :
              <></>
        }
        {newImage &&
          <div className='ChangeImagePage-image-div'>
            <label htmlFor='newImage'>{t('New image')}</label>
            <img onClick={() => {openModal(); setImageInModal(ImageInModal.New); setContentInModal(ModalContent.Image)}} alt={t('New image to upload')} id='newImage' className='ChangeImagePage-image' src={URL.createObjectURL(newImage)} />
            <ButtonSecondary type={SecondaryButtonType.button} onClick={() => setNewImage(undefined)} ariaLabel={t('Remove image {{newImageName}}', newImageName)}>{t('Remove')}</ButtonSecondary>
          </div>}
      </div>
      <div className='ChangeImagePage-button-section'>
        <ChooseImageButton />
        {currentImage && org.profilePageOptions.removeImage() &&
        <ButtonSecondary fullWidth type={SecondaryButtonType.button} onClick={() => {setContentInModal(ModalContent.RemoveImage);openModal()}} disabled={isRemovingImage}>
          { isRemovingImage ? <><LoadingSpinner ariaLabel={t('Removing current image')}/>{t('Removing current image')} </> : t('Remove current image')} 
        </ButtonSecondary>}
      </div>
      <ChangeImagePageModal show={showModal} handleClose={hideModal} content={contentInModal} image={imageInModal} />
    </ContentContainer>
  )
}

export default ChangeImagePage;