export class FailedToUploadImageError extends Error {
  name = 'FailedToUploadImageError'
}

export class FailedToFetchImageError extends Error {
  name = 'FailedToFetchImageError'
}

export class MissingImageError extends Error {
  name = 'MissingImageError'
}

export class ImageFormatError extends Error {
  name = 'ImageFormatError'
}

export class ImageSizeError extends Error {
  name = 'ImageSizeError'
}

export class RemoveImageError extends Error {
  name = 'RemoveImageError'
}

export class Image {
  constructor(
    readonly image: string,
  ) {}
}

export async function initFetchImage(orgID: string,accountID: string): Promise<Image> {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      id: accountID,
    }),
  };

  const res = await fetch(`/api/accounts/${orgID}/fetch-image`, requestOptions);
  if (!res.ok) {
    const responseText = (await res.text()).trim()
    if (res.status === 500 && responseText === 'Image format error') {
      throw new ImageFormatError();
    } else {
      throw new FailedToFetchImageError();
    }
  }  
  const image = await res.json();

  if (image.image == '' ) {
    // Backend has reported a successfull request to image source system,
    // but no image was found on the user.
    throw new MissingImageError();
  }

  return new Image(
    image.image,
  )
}

interface UploadImageParams {
  orgID: string;
  accountID: string;
  image: Blob;
  orientation: number;
}
export async function initUploadImage({orgID, accountID, image, orientation}:UploadImageParams): Promise<void> {
  const formData = new FormData();
  formData.append('id', accountID);
  formData.append('image', image);
  formData.append('orientation', orientation.toString());

  const res = await fetch(`/api/accounts/${orgID}/upload-image`, {
    method: 'POST',
    body: formData,
  });

  if (!res.ok) {
    const responseText = (await res.text()).trim();
    if (res.status === 400 && responseText === 'Uploaded image file size too large') {
      throw new ImageSizeError();
    } else if (res.status === 500 && responseText === 'Image format error') {
      throw new ImageFormatError();
    } else if (res.status === 500 && responseText === 'Image target system error') {
      throw new FailedToUploadImageError();
    } else {
      throw new Error ('Error uploading image: ' + responseText);
    }
  }
}

export async function initRemoveImage(orgID: string, accountID: string): Promise<void> {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      id: accountID,
    }),
  }

  const res = await fetch(`/api/accounts/${orgID}/remove-image`, requestOptions);
  if (!res.ok) {
    const responseText = (await res.text()).trim();
    if (res.status === 500 && responseText === 'Image target system error') {
      throw new RemoveImageError();
    } else {
      throw new Error ('Error removing image: ' + responseText);
    }
  }
}